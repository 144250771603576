.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 1rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: aliceblue;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color: grey;
    padding: 2rem;
    gap: 1rem;
    color: aliceblue;
    justify-content: space-between;
}
.category:hover{
    background-color: var(--orange);
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem; fill: aliceblue;
}
.category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
}
.category>:nth-child(3){
   font-size: 0.9rem;
   line-height: 25px;
}
@media screen and (max-width:768px) {
        .programs-header{
            flex-direction: column;
            font-size: x-large;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            text-align: center;
        }
        .program-categories{
            flex-direction: column;
        }
}