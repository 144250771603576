.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: 20rem; 
}
.link-1>a>img{
    display: flex;
    width: 2rem;
    height: 2rem;
    /* cursor: pointer; */
}
.link-2>a>img{
    display: flex;
    width: 2rem;
    height: 2rem;
    /* cursor: pointer; */
}
.link-3>a>img{
    display: flex;
    width: 2rem;
    height: 2rem;
    /* cursor: pointer; */
}
.logo-f>img{
    display: flex;
    bottom: 0;
    width: 8rem;
    height: 6rem;
    border-radius: 20px;
    background-color: transparent;
}
.f-blur-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(170px);
    background-color: rgba(255, 47, 0, 0.797);
}
.f-blur-2{
    bottom: 0;
    left: 10%;
    width: 26rem;
    height: 12rem;
    filter: blur(170px);
    background-color: var(--orange);
}
@media screen and (max-width:768px){
    .footer{
        display: flex;
        flex-direction: column;
    }
}
