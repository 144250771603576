.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
display: flex;
align-items: center;
justify-content: center;
gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    gap: 2rem;
    color: aliceblue;
    padding: 1.5rem;
    width: 15rem;
}
.plan:hover{
    background-color: var(--orange);
    cursor: pointer;
}
.plan:nth-child(2){
    background-color: goldenrod;
    transform: scale(1.1);
}
.plan>svg{
    fill: var(black);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
font-size: 1.5rem;
font-weight: bold;
}
.plan>:nth-child(3){
font-size: 1rem;
font-weight: bold;
}
.features{
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    gap: 0.5rem;
}
.p-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}
.p-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0;
}
@media screen and (max-width:768px){
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}
